<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Reporte de Clientes</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div class="
        flex
        align-items-center
        flex-column
        lg:justify-content-center lg:flex-row
      ">
      <h4><strong>REPORTE GENERAL DE CLIENTES/VENTAS</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <label for="fecha_venta_desde"><strong>FECHA VENTA DESDE: </strong></label>
            <Calendar id="fecha_venta_desde" v-model="fecha_venta_desde" :showIcon="true" dateFormat="dd/mm/yy" />
          </div>
          <div class="field col-12 md:col-3">
            <label for="fecha_venta_hasta"><strong>FECHA VENTA HASTA: </strong></label>
            <Calendar id="fecha_venta_hasta" v-model="fecha_venta_hasta" :showIcon="true" dateFormat="dd/mm/yy" />
          </div>
          <div class="field col-12 md:col-3">
            <label for="sucursal"><strong>SUCURSAL: </strong></label>
            <Dropdown v-model="sucursalSelected" :options="sucursales" optionLabel="nombre" optionValue="id">
            </Dropdown>
          </div>
          <div class="field col-12 md:col-3">
            <label for="cliente"><strong>CLIENTE: </strong></label>
            <Dropdown v-model="clienteSelected" :options="clientes" optionLabel="nombre" optionValue="id" :filter="true">
            </Dropdown>
          </div>
          <div class="field col-12 md:col-3">
            <label for="fecha_vencimiento_hasta"><strong>ACCIONES: </strong></label>
            <Button label="APLICAR FILTROS" icon="pi pi-search" class="p-button-primary mr-2" :loading="enviando"
              :disabled="enviando" @click="BuscarFiltro" />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <DataTable ref="dtrepclientes" key="id" :value="clientesReporte" responsiveLayout="scroll" :loading="enviando"
        class="p-datatable-sm" :filters="buscar" responsive="true" stripedRows showGridlines :rows="10"
        :paginator="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 100, 500, 1000, 5000, 10000]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Reportes de Clientes">
        <template #header>
          <div class="
              flex flex-column
              md:flex-row md:justify-content-end md:align-items-end
            ">
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText v-model="buscar['global'].value" placeholder="Buscar cliente..." />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <Button v-if="'R_Clientes DescargarPDF' in auth.user.permissions" label="PDF Clientes" type="button"
                icon="pi pi-file-pdf" class="p-button-outlined p-button-danger" v-tooltip.top="'Exportar PDF Clientes'"
                :loading="generando_pdf" :disabled="generando_pdf" @click="generarPDF()" />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <Button v-if="'R_Clientes DescargarEXCEL' in auth.user.permissions" label="EXCEL Exportar" type="button"
                icon="pi pi-file-excel" class="p-button-outlined p-button-success" v-tooltip.top="'Exportar Clientes'"
                :loading="exportando" :disabled="exportando" @click="exportClientesVentas()" />
            </span>
          </div>
        </template>
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="p-invalid">
            Debe aplicar filtros para ver resultados en la tabla!</span>
        </template>
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="id" header="CÓDIGO" :sortable="true"></Column>
        <Column field="nombre_cliente" header="CLIENTE"> </Column>
        <Column field="nit" header="NIT"> </Column>
        <Column field="total_cancelar" header="TOTAL VENTAS" :sortable="true">
        </Column>
        <Column field="descuento" header="TOTAL DESCUENTOS" :sortable="true">
        </Column>
        <Column headerStyle="min-width:10rem;" header="ACCIONES">
          <template #body="slotProps">
            <Button class="p-button-icon-only p-button-raised" icon="pi pi-ellipsis-v" v-tooltip.top="'Ver Acciones'"
              @click="$refs['menu' + slotProps.data.id].toggle($event)" />
            <Menu :ref="'menu' + slotProps.data.id" :popup="true" :model="acciones(slotProps.data)">
            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api";
import SucursalService from "@/service/SucursalService";
import ClienteService from "@/service/ClienteService";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      enviando: false,
      generando_pdf: false,
      exportando: false,
      sucursales: [{ id: 0, nombre: "TODAS" }],
      clientes: [{ id: 0, nombre: "TODOS" }],
      clientesReporte: [],
      clienteSelected: 0,
      sucursalSelected: 0,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      fecha_venta_desde: null,
      fecha_venta_hasta: null,
    };
  },
  clienteService: null,
  sucursalService: null,
  auth: null,
  created() {
    this.clienteService = new ClienteService();
    this.sucursalService = new SucursalService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarClientes();
  },
  methods: {
    acciones(datos) {
      return [
        {
          label: "Ver Ventas",
          icon: "pi pi-eye",
          to: `/cliente_ventas/${datos.id}/detalle`,
        },
      ];
    },
   /*  verVentas(cliente) {
      this.$router.push(`/cliente_ventas/${cliente.id}/detalle`);
    }, */
    cargarSucursales() {
      this.sucursalService.getSucursalesAll().then((response) => {
        response.forEach((sucursal) => {
          this.sucursales.push(sucursal);
        });
      });
    },
    cargarClientes() {
      this.clienteService.getClientesAll().then((response) => {
        response.forEach((cliente) => {
          this.clientes.push(cliente);
        });
      });
    },
    BuscarFiltro() {
      this.clientesReporte = [];
      this.enviando = true;
      this.cargarReporteClientes();
    },
    cargarReporteClientes() {
      let datos = {
        sucursal: this.sucursalSelected,
        cliente: this.clienteSelected,
        fecha_venta_desde: this.fecha_venta_desde,
        fecha_venta_hasta: this.fecha_venta_hasta,
      };
      this.clienteService.filtrarClientes(datos).then((data) => {
        this.clientesReporte = data.clientesReporte;
        this.enviando = false;
      });
    },
    exportClientesVentas() {
      if (this.clientesReporte.length > 0) {
        this.exportando = true;
        let datos = {
          sucursal: this.sucursalSelected,
          cliente: this.clienteSelected,
          fecha_venta_desde: this.fecha_venta_desde,
          fecha_venta_hasta: this.fecha_venta_hasta,
        };
        this.clienteService.exportarClientes(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se ha exportado correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    generarPDF() {
      if (this.clientesReporte.length > 0) {
        this.generando_pdf = true;
        let datos = {
          sucursal: this.sucursalSelected,
          cliente: this.clienteSelected,
          fecha_venta_desde: this.fecha_venta_desde,
          fecha_venta_hasta: this.fecha_venta_hasta,
        };
        this.clienteService.generarPDF(datos).then(() => {
          this.generando_pdf = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se genero el reporte correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}

.p-fondo {
  background-color: #7bffae;
}
</style>
